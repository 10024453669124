import React, { useEffect } from "react";
import PropertyCard from "../Components/Home/PropertyCard";
import tw from "tailwind-styled-components";
import { useSelector } from "react-redux";

const Container = tw.div`max-w-6xl w-full items-center mx-auto py-20`;
const GridContainer = tw.div`grid  py-4 px-4  grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3  gap-4 gap-y-10   items-center`;
const Title = tw.h1`font-jost text-2xl font-semibold group font-jost mx-4 my-4`;

const PropertyListing = () => {
  const data = useSelector(
    (state) => state.UserReducer.userDetails?.data?.data
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Title>
        Properties <hr className="border-b-2 border-black w-16 mt-2" />
      </Title>
      <GridContainer>
        {Array.isArray(data) &&
          data.map((item) => <PropertyCard data={item} key={item.id} />)}
      </GridContainer>
    </Container>
  );
};
export default PropertyListing;
