import React, { useEffect, useState } from "react";
import Hero from "../Components/Home/Hero";
import Features from "../Components/Home/Features";
import Testimonials from "../Components/About/Testimonials";
import Benefits from "../Components/Home/Benefits";
import WorkSpace from "../Components/Home/WorkSpace";
import { useQuery } from "react-query";
import axios from "axios";
import Config from "../Config";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../Redux/action";
import NotFoundModel from "../Components/NotFoundModel";
import Loading from "../Components/Loading";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [gotInitialResp, setGotInitialResp] = useState(false);
  const detailFunction = async () => {
    const clientId = 113;
    if (!clientId) return;
    return await axios.get(`${Config.ApiUrl}properties/${clientId}`);
  };

  const {
    data,
    error,
    refetch,
    isLoading: InitialLoading,
  } = useQuery(`getTenantDetail`, detailFunction, {
    enabled: true,
    onSuccess: (data) => {
      dispatch(setUserDetails(data));
      setGotInitialResp(true);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  // if (error) {
  //   return <NotFoundModel />;
  // }
  return (
    <>
        <>
          <Hero />
			{!InitialLoading &&
          <WorkSpace data={data} />
			}
          <Features />
          <Benefits />
          <Testimonials />
        </>
    </>
  );
};

export default Home;
