import tw from "tailwind-styled-components"
import PropertyCard from "./PropertyCard"
import { Link } from "react-router-dom"


export const Container = tw.div`flex flex-col items-center justify-center max-w-6xl mx-auto px-4 pt-16 text-center `
export const Subtitle = tw.span`text-blue-500 font-maven mb-6 font-semibold`
export const Title = tw.h1`md:text-4xl font-jost text-2xl font-semibold uppercase text-center  group font-jost`
export const Highlight = tw.span`text-[#ff6b6b]`
export const Description = tw.p`text-gray-600 font-maven mb-12 max-w-3xl mx-auto font-maven mt-4`
export const GridContainer = tw.div`grid md:mx-32 py-4 px-4 md:pt-16 pb-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3  gap-4 gap-y-10   items-center`
const WorkSpace = ({ data }) => {
  // console.log(data?.data?.data?.data)
  const propData = data?.data?.data
  // console.log(propData)
  return (
    <div className="bg-[#F6FCFF]">
      <Container>
        <Subtitle>Coliving spaces</Subtitle>
        <Title>
          Building A{" "}
          <Highlight>Better Coliving:</Highlight>{" "}
          Our Mission <br />And Values
        </Title>
        <Description>
          Our mission is to revolutionize urban living by creating vibrant, inclusive, and
          hassle-free co-living spaces that empower individuals to thrive in a supportive
          community.
        </Description>

      </Container>
      {/* {InitialLoading && <Loading />} */}
      <GridContainer>

        {Array.isArray(propData) &&
          propData.slice(0, 3).map((item) => (
            <PropertyCard data={item} key={item.id} />
          ))}
      </GridContainer>
      {Array.isArray(propData) && propData.length > 3 && (
        <div className="flex justify-center items-center pb-4">
          <Link to="/property-listing">
            <button className="underline text-rose-400 font-semibold text-lg">
              Show More
            </button>
          </Link>
        </div>
      )}

      {/* <div className="flex justify-center items-center pb-4">
        <Link to="/property-listing">
          <button className="underline text-rose-400 font-semibold text-lg ">Show More</button>
        </Link>
      </div> */}
    </div>
  )
}
export default WorkSpace