
import { useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import ImageCarousel from "./ImageCarousel";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { FaCircleCheck } from "react-icons/fa6";
import "animate.css/animate.min.css";

// Styled Components using tailwind-styled-components
const CardWrapper = tw.div`bg-white border w-full overflow-hidden border border-sky-400 `;
const ImageContainer = tw.div`w-full h-[180px] object-cover pointer-cursor`;
const CardDetails = tw.div`p-5`;
const CardHeader = tw.div`items-center w-full`;
const PropertyName = tw.div`font-semibold font-jost text-xl mb-3`;
const RentAmount = tw.div`text-lg mt-3 font-maven flex`;
const TenantPreference = tw.div`flex justify-between gap-2 font-maven font-medium items-center w-full text-gray-600`;
const LocationText = tw.div`text-gray-600 flex justify-start font-maven items-center mt-3`;
const PreferenceTag = tw.div`font-medium font-maven text-sm px-2 rounded-full flex justify-center items-center gap-2 truncate text-white`;
const MoreButton = tw.button`w-full font-jost font-thin mt-4 py-3 border border-sky-500 text-sky-500 hover:text-white  hover:bg-sky-500`;
function PropertyCard({ data }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigation = useNavigate();

    function getPreferrence(data) {
        if (data === 1)
            return <PreferenceTag className="bg-blue-500">Boys</PreferenceTag>;
        else if (data === 2)
            return <PreferenceTag className="bg-pink-500">Girls</PreferenceTag>;
        else
            return <PreferenceTag className="bg-purple-500">Boys & Girls</PreferenceTag>;
    }

    return (
        // <ScrollAnimation animateIn="swing" >
        <CardWrapper>
            <ImageContainer
                onClick={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true);
                }}
            >
                <img
                    src={data?.images[0]}
                    alt=""
                    className="w-full h-full object-cover"
                />
            </ImageContainer>
            <CardDetails >
                <CardHeader>
                    <PropertyName>{data?.name}</PropertyName>

                </CardHeader>

                <TenantPreference>{getPreferrence(data?.tenantPreference)}</TenantPreference>
                <LocationText>
                    <IoLocationOutline />
                    {data?.address}
                </LocationText>
                <RentAmount ><FaCircleCheck className="mr-3 mt-1 text-rose-500" /><span className="font-semibold">₹ {data?.rent}{" "}</span>/Month</RentAmount>
                {/* <ButtonWrapper> */}
                <MoreButton onClick={() => navigation(`/property/${data?.id}`)}>
                    More Details
                </MoreButton>
                {/* </ButtonWrapper> */}
            </CardDetails>

            {isModalOpen && <ImageCarousel setIsModalOpen={setIsModalOpen} data={data} />}
        </CardWrapper>
        // </ScrollAnimation>
    );
}

export default PropertyCard;
