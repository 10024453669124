import React from "react";
import tw from "tailwind-styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { Images } from "../../Images";

const Container = tw.div`w-full py-16 px-4`;
const Content = tw.div`max-w-6xl mx-auto text-center`;
const SubTitle = tw.p`text-blue-400 text-lg font-semibold mb-4 font-maven`;
const Title = tw.h2`md:text-4xl text-2xl font-semibold uppercase text-center  group font-jost`;
const Description = tw.p`text-gray-600 max-w-3xl mx-auto mb-12 font-maven mt-4`;
const TestimonialCard = tw.div`px-8 py-6 mx-4 `;
const QuoteRight = tw.div`absolute text-[120px] text-blue-300 font-serif bottom-0 right-0 leading-none`;
const TestimonialText = tw.p`text-gray-600 mb-6 relative z-10 font-maven`;
const ProfileContainer = tw.div`flex items-center justify-center gap-4`;
const ProfileImage = tw.img`w-16 h-16 rounded-full object-cover`;
const ProfileInfo = tw.div`text-left`;

const testimonials = [
  {
    text: "I had a wonderful stay at this apartment! The rooms were spacious, clean, and well-maintained. The landlord was very cooperative, addressing any issues promptly. The location is ideal, with easy access to public transport, grocery stores, and cafes. I would highly recommend this place to anyone looking for a comfortable and hassle-free living experience.",
    name: "Riya",
    role: "Freelancer",
    image: Images.UserLanding,
    nameColor: "text-blue-400"
  },
  {
    text: "This flat exceeded my expectations. The building has excellent security measures, and the apartment itself is modern with all necessary amenities. The neighbors are friendly, and the area is peaceful, making it a great place to unwind after work. I loved the nearby park for morning jogs!",
    name: "Anamika",
    role: "Real Estate",
    image: Images.UserLanding,
    nameColor: "text-[#ff6b6b]"
  },
  {
    text: "Staying here was an absolute delight. The PG offered a homely vibe, with well-equipped rooms and a supportive staff. The high-speed internet and quiet surroundings were perfect for remote work. I appreciated how the owner kept the common areas clean and comfortable for all tenants.",
    name: "Annu",
    role: "Designer",
    image: Images.UserLanding,
    nameColor: "text-green-500"
  },
  {
    text: "I stayed in this flat for six months, and it was the best rental experience I’ve had so far. The interior design is lovely, with lots of natural light coming through. The landlord went above and beyond to ensure the tenants were comfortable. I’ll definitely miss this place!",
    name: "Nancy",
    role: "Developer",
    image: Images.UserLanding,
    nameColor: "text-purple-500"
  }
];

export default function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    customPaging: () => (
      <div className="w-2 h-2 mx-1 bg-gray-300 rounded-full hover:bg-blue-400 transition-colors" />
    ),
    dotsClass: "slick-dots !flex !justify-center !items-center !gap-2 !relative !bottom-0 mt-8",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <Container>
      <Content>
        <SubTitle>Customers Trust</SubTitle>
        <Title>
          What <span className="text-[#ff6b6b]">People Say</span> About Us
        </Title>
        <Description>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
        </Description>

        <Slider {...settings} className="bg-[#F9FDFF]">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index}>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                animateOnce={true} // Ensures the animation runs only once
                duration={1} // Animation duration in seconds
              >
                <div className="bg-white p-4">
                  {/* <QuoteLeft>"</QuoteLeft> */}
                  <TestimonialText>{testimonial.text}</TestimonialText>
                  <QuoteRight>"</QuoteRight>
                  <ProfileContainer>
                    <ProfileImage src={testimonial.image} alt={testimonial.name} />
                    <ProfileInfo>
                      <h4 className={`font-semibold font-maven text-lg ${testimonial.nameColor}`}>{testimonial.name}</h4>
                      <p className="text-gray-500 font-maven">{testimonial.role}</p>
                    </ProfileInfo>
                  </ProfileContainer>
                </div>
              </ScrollAnimation>
            </TestimonialCard>
          ))}
        </Slider>
      </Content>
    </Container>
  );
}

