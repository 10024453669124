import React, { useEffect } from "react";
import tw from "tailwind-styled-components";
import "animate.css/animate.min.css";
import ContactForm from "../Components/Contact/ContactForm";
import axios from "axios";
import Config from "../Config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

// Styled Components
const Container = tw.div`w-full min-h-screen bg-[#F7FDFF] py-16`;
const Hero = tw.div`relative h-[270px] flex items-center justify-center bg-gray-900`;
const HeroOverlay = tw.div`absolute inset-0 bg-black/50`;
const HeroTitle = tw.h1`text-white text-4xl font-semibold z-10 font-jost `;

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Contact = async (values) => {
    return await axios.post(`${Config.ApiUrl}contact`, values, {
      //   headers: {
      //     authorization: "Bearer" + " " + token,
      //     "Content-Type": "application/json",
      //   },
    });
  };

  const onContactSuccess = (data) => {
    toast.success(data?.data?.msg || "Success");
  };

  const onContactError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: ContactLoading, mutate: postContact } = useMutation(
    Contact,
    {
      onSuccess: onContactSuccess,
      onError: onContactError,
    }
  );

  return (
    <Container>
      <Hero
        style={{
          backgroundImage: `url('../../Image/contact.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <HeroOverlay />

        <HeroTitle>GET IN TOUCH</HeroTitle>
      </Hero>
      <ContactForm contactLoading={ContactLoading} postContact={postContact} />
    </Container>
  );
}
